import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import {
  FormContextType,
  FormOptions,
  FormOptionsSustainability,
} from "../../../../@types";
import { useValidation } from "../../../../hooks/useValidator";
import { fetchAddressData } from "../../../../helpers/addressUtils";

const FormContext = createContext<FormContextType | undefined>(undefined);

const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formValues, setFormValues] = useState<
    FormOptions | FormOptionsSustainability
  >({});
  const [buildingPhoto, setBuildingPhoto] = useState("");
  const [map, setMap] = useState("");
  const [houseAddress, setHouseAddress] = useState("");
  const { errors, validate } = useValidation();
  const [postalAddress, setPostalAddress] = useState("");

  const fetchAddress = useCallback(async () => {
    try {
      const result = await fetchAddressData(postalAddress);
      if (result) {
        setBuildingPhoto(result.imageUrl);
        setHouseAddress(result.houseAddress);
        setMap(result.mapUrl);
      }
    } catch (error) {
      setBuildingPhoto("/images/empty-image.svg");
      setMap("/images/empty-image.svg");
    }
  }, [postalAddress]);

  return (
    <FormContext.Provider
      value={{
        formValues,
        setFormValues,
        errors,
        validate,
        buildingPhoto,
        houseAddress,
        map,
        fetchAddressData: fetchAddress,
        setPostalAddress,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export { FormProvider, useFormContext };
