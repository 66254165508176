import { useState, FormEvent, useEffect } from "react";
import { referenceFields } from "./referencefields";
import FormBody from "../FormBody";
import { ReferenceImage } from "../../../assets/images/api/APIimages";
import { FormOptions, FormOptionsSustainability } from "../../../@types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postReference } from "../../../redux/actions/referenceActions";
import { useFormContext } from "../../../pages/Api/usage-pages/components/FormContext";

const ReferenceForm = () => {
  const sectionFields = [
    { title: "Adres", startIndex: 0, endIndex: 4 },
    { title: "Huidige situatie", startIndex: 5, endIndex: 24 },
  ];
  const apiKey = useAppSelector((state) => state.auth.user?.api_key);

  // const [houseOptions, setHouseOptions] = useState<
  //   FormOptions | FormOptionsSustainability
  // >({
  //   postcode: "",
  //   housenumber: "",
  // });
  const { formValues, setFormValues } = useFormContext();
  const { savedQueries } = useAppSelector((state) => state.reference);

  useEffect(() => {
    if (Object.keys(savedQueries).length > 0) {
      setFormValues({
        ...(savedQueries as FormOptions),
      });
    }
  }, [savedQueries, setFormValues]);

  const dispatch = useAppDispatch();

  const handleReferenceFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newFormData: FormOptions = {
      postcode: "",
      housenumber: "",
    };

    // Type conversion and validation before submission
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== undefined) {
        const field = referenceFields.find((f) => f.name === key);
        if (field) {
          let convertedValue = value;

          // Handle type conversions based on field type
          switch (field.type) {
            case "number":
              convertedValue = Number(value);
              if (isNaN(convertedValue)) {
                console.error(`Invalid number value for field: ${key}`);
                return;
              }
              break;
            case "select":
              const numValue = Number(value);
              convertedValue = isNaN(numValue) ? value : numValue;
              break;
            case "radio":
              convertedValue = value === "true";
              break;
            case "text":
              if (key === "postcode") {
                convertedValue = value
                  .toString()
                  .split(" ")
                  .join("")
                  .toUpperCase();
              }
              break;
          }

          newFormData[key as keyof FormOptions] = convertedValue;
        }
      }
    });

    if (apiKey) {
      dispatch(postReference({ formData: newFormData, apiKey }));
    }
  };

  return (
    <div>
      <FormBody
        title={"Woningreferenties opvragen"}
        sectionFields={sectionFields}
        desc={
          "Vraag volledig interactief maximaal 30 relevante en vergelijkbare recente Kadaster transacties op voor het doelobject die vóór de opgegeven waarderingsdatum hebben plaatsgevonden."
        }
        img={ReferenceImage}
        path={"https://docs.altum.ai/apis/interactieve-woningreferentie-api"}
        initialFields={referenceFields}
        handleSubmit={handleReferenceFormSubmit}
      />
    </div>
  );
};

export default ReferenceForm;
