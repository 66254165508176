import { useState } from "react";
import Logo from "../Logo";
import { Avatar } from "flowbite-react";
import { useAppSelector } from "../../redux/hooks";
import { CiBellOn } from "react-icons/ci";
import Text from "../Text";
import { CgMenuRightAlt } from "react-icons/cg";
import Drawer from "./Drawer";
import { Link } from "react-router-dom";
type Props = {};

const Navbar = (props: Props) => {
  const { user } = useAppSelector((state) => state.auth);
  const { comingInvoice } = useAppSelector((state) => state.portal);
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <nav className="flex justify-between p-4 shadow-[0px_0px_6px_0px_#00000026] z-10 ">
        <Link to="/">
          <Logo className="w-36" />
        </Link>
        <div className="flex items-center">
          <CiBellOn size={30} className="" />
          <div className="flex gap-2 items-center hidden md:flex">
            <Avatar
              className="w-8"
              img={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                user?.first_name || user?.email || "User",
              )}`}
              onError={(e: React.SyntheticEvent<HTMLDivElement, Event>) => {
                const img = e.currentTarget.querySelector("img");
                if (img) {
                  img.src = "/images/empty-image.svg";
                  img.alt = "User avatar";
                }
              }}
            />
            <div className="flex flex-col">
              <Text>Welkom, {user?.first_name || user?.email}</Text>
              {!!comingInvoice && (
                <Text className="text-gray-light text-xs">
                  {comingInvoice.product.name}
                </Text>
              )}
            </div>
          </div>

          <CgMenuRightAlt
            size={30}
            className="text-primary md:hidden"
            onClick={() => setOpenDrawer(true)}
          />
        </div>
      </nav>
      <Drawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </>
  );
};

export default Navbar;
