import axios from "axios";
import { toast } from "react-toastify";

interface AddressData {
  imageUrl: string;
  houseAddress: string;
  mapUrl: string;
}

export async function fetchAddressData(
  postalAddress: string,
): Promise<AddressData | null> {
  if (postalAddress === "") {
    return null;
  }
  try {
    const { data } = await axios.post<any>("/api/v1/mopsus/address-data", {
      house_address: postalAddress,
    });
    const { imageBuffer, mapBuffer, houseAddress } = data.data;
    const blobImage = new Blob([new Uint8Array(imageBuffer.data)], {
      type: "image/jpeg",
    });
    const blobMap = new Blob([new Uint8Array(mapBuffer.data)], {
      type: "image/png",
    });

    return new Promise((resolve, reject) => {
      const readerImage = new FileReader();
      const readerMap = new FileReader();

      readerImage.onloadend = () => {
        const imageUrl = readerImage.result as string;
        // Read the map Blob
        readerMap.onloadend = () => {
          const mapUrl = readerMap.result as string;
          resolve({ imageUrl, houseAddress, mapUrl });
        };
        readerMap.onerror = reject;
        readerMap.readAsDataURL(blobMap); // Read the map Blob
      };
      readerImage.onerror = reject;
      readerImage.readAsDataURL(blobImage); // Read the image Blob
    });
  } catch (err: any) {
    //toast.error("Could not get house address image");
    throw err;
  }
}
